import React from 'react';

import DetailUploadScreen from './components/DetailUploadScreen';

function App() {
  return (
    <DetailUploadScreen></DetailUploadScreen>
  );
}

export default App;
