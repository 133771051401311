
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import SingleRecord from "./SingleRecord";
import { UserData } from "../types/user";

const FormContainer = styled.div`
  background: linear-gradient(168deg, #fff 33%, #e0ecf5);
  display: grid;
  grid-auto-rows: min-content 1fr min-content;
  grid-auto-flow: row;
  justify-content: center;
  height: 100%;
  padding: 30px 50px;
  overflow-y: auto;

  h3 {
    color: #34bebd;
    font-family: "Lato", sans-serif;
    margin-bottom: 20px;
  }

  h1 {
    margin-top: 50px;
  }

  h1,
  h3 {
    text-align: left;
  }
`;

const LogoContainer = styled.div`
  display: flex; 
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
`
  ;

const VouchLogo = styled.img`
`;

const HtmLogo = styled.img`
  max-width: 140px;
`;

const Button = styled.button`
  background-color: #34bebd;
  margin: 10px;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #34bebd;
  width: 150px;
  height: 60px;
  letter-spacing: 0.23px;
  color: white;
  font: bold 15px Lato, sans-serif;
  outline: none;
  &:disabled {
    background: #f2f2f2;
    border-color: #082842;
    color: #082842;
    pointer-events: none;
  }
`;

const ErrorScreenMessage = styled.div`
  text-align: center;
  margin: 150px auto;
`
  ;

const ErrorImage = styled.img`
  margin: 30px 0;
  width: 300px;
  `
  ;

const ContentContainer = styled.div`
  overflow-y:auto;  
  `;


const DoneContainer = styled.div`
  margin-top: 150px;
  display: grid;
  place-items: center;

  img {
    max-width: 300px;
    margin-top: 15px;
  }
`;

const ButtonContainer = styled.div`
  width:100%;
  text-align:right;
  max-width:800px;
  margin: 0 auto;
`
  ;

interface Props { }

const DetailUploadScreen: React.FC<Props> = () => {
  const [step, setStep] = useState(0);
  const [customerData, setCustomerData] = useState<any>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const cId = queryParams.get("cId") || "";

  const [form, setForm] = useState<any>({
    commissionId: "",
    llName: "",
    llEmail: "",
    moveOut: "",
    isAgencyAddress: "",
    address_line_1: "",
    address_line_2: "",
    address_county: "",
    address_post_town: "",
    address_postcode: "",
    utilities: "",
    correspondance_address_udprn: "",
    correspondance_address_line1: "",
    correspondance_address_line2: "",
    correspondance_address_county: "",
    correspondance_address_post_town: "",
    correspondance_address_postcode: "",
    isPropertyVacant: "",
    agentUpdated: true
  });

  useEffect(() => {
    const reqBody = {
      query: `query{
          getUser(customerId:"${cId}") {
            commissionId
            moveOut
            vacated
            utilities
            isMoveOut
            isVacated
            llEmail
            llName
            isAgencyAddress
            address_line_1
            address_line_2
            address_county
            address_post_town
            isPropertyVacant
            address_postcode
            correspondance_address_udprn
            correspondance_address_line1
            correspondance_address_line2
            correspondance_address_post_town
            correspondance_address_county
            correspondance_address_postcode
            contactAddress{
              address_line_1
              address_line_2
              address_post_town
              address_county
              address_postcode
            }
          }
         } `,
    };

    fetch(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        setCustomerData(resData.data.getUser);
        if (resData.data.getUser.length === 0) {
          setSubmitted(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  function checkFormValidity(value: boolean){
    setValid(value)
  }

  function onFormSubmit(formData: any) {
    console.log(formData);

    const reqBody = {
      query: `mutation($users: [NewUserInput!]){
        updateUsers(newUsers: $users cId: "${cId}")
      }`,
      variables: {
        users: [...formData],
      },
    };

    fetch(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function goNext() {
    const userInfo = [...customerData];
    userInfo[step] = form;
    setCustomerData(userInfo);

    if (step === customerData.length - 1) {
      onFormSubmit(userInfo);
    } else {
      const newStep = step + 1;
      setStep(newStep);
      setForm(userInfo[newStep]);
    }
  }

  function goBack() {
    if (step !== 0) {
      const newStep = step - 1;
      const userInfo = [...customerData];
      setForm(userInfo[newStep]);
      setStep(newStep);
    }
  }

  if (!customerData) {
    return <div>Loading...</div>;
  }

  return (
    <FormContainer>
      <LogoContainer>
        <VouchLogo src="/img/logo.svg" alt="the vouch logo"></VouchLogo>
        <HtmLogo src="/img/htmlogo.svg" alt="the vouch logo"></HtmLogo>
      </LogoContainer>
      <ContentContainer>


        {customerData.length === 0 && !cId && (
          <ErrorScreenMessage>
            <h2>Oops! No data found!</h2>
            <p>It looks like you've landed on this page by mistake</p>
            <ErrorImage src="/img/empty.svg" alt="an illustration showing a man holding an empty box"></ErrorImage>
          </ErrorScreenMessage>
        )}
        {customerData.length > 0 && !submitted && (
          <SingleRecord
            index={step}
            users={customerData}
            step={step}
            cId={cId}
            numOfDocs={customerData.length}
            item={customerData[step]}
            form={form}
            checkFormValidity={checkFormValidity}
            key={step}
            setForm={setForm}
          ></SingleRecord>
        )}
        {submitted && cId && (
          <DoneContainer>
            All done! We could not find any missing records for your commissions.
            <img src="/img/done.svg" />
          </DoneContainer>
        )}
      </ContentContainer>
      <ButtonContainer>
        {step === 0 || (submitted && cId) ? null : (
          <Button
            onClick={(e) => {
              goBack();
            }}
          >
            Previous
          </Button>
        )}
        {customerData.length - 1 === step || submitted || customerData.length === 0 ? null : (
          <Button disabled={!valid}
            onClick={(e) => {
              goNext();
            }}
          >
            Next
          </Button>
        )}
        {customerData.length - 1 === step && !submitted ? (
          <Button disabled={!valid}
            onClick={(e) => {
              goNext();
            }}
          >
            Submit
          </Button>
        ) : null}
      </ButtonContainer>
    </FormContainer>
  );
};

export default DetailUploadScreen;