
import React, { useState, useEffect } from "react";

import { TextInput } from "vouch.ui.react.components";
import SteppedInput from "../components/SteppedInput";
import styled from "styled-components";
import { UserData } from "../types/user";

interface Props {
  index: number;
  step: number;
  numOfDocs: number;
  cId: string;
  users: UserData[];
  item: any;
  form: any;
  setForm: Function;
  checkFormValidity: Function;
}

const Counter = styled.div`
  display: flex; 
  justify-content: flex-end;
  padding-right: 10px;
`;
const PanelContainer = styled.div`
display: grid;
justify-content: space-between;
text-align: left;
flex-flow:row wrap;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: min-content 1fr;
gap: 0px 0px;
grid-template-areas:
"preview"
"details";
`;
const RecordComponent = styled.div`

`;
const AddressContainer = styled.div`

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  div:nth-child(1) {
    flex: 1 1 58%;
    margin-left:5px;
  }
  div:nth-child(2) {
    flex: 1 1 40%;
    min-width: 250px;
  }
`;

const PreviewPanel = styled.div`
background-color: #f2f2f2;
border-radius: 25px;
padding: 20px;
grid-area: preview;
height: fit-content;
flex: 0 1 300px;
color: #082842;
max-height:700px;
margin:0 5px 30px;

#preview-content{
  display: grid;
  grid-auto-columns: 50% 50%;
  grid-auto-flow: column;
}

h4{
  margin: 0;
}
`;
const Label = styled.div`
  font-weight: bold; 
  display: block; 
  padding: 10px 0;
`;
const Spacer = styled.div`
padding: 20px 0;

p {
margin: 10px 5px 25px;
}`;

const Select = styled.select`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(188, 190, 192, 0.5);
    option {
      padding: 10px;
    }`;

const LabelContainer = styled.div`
  margin: 10px 5px;
`;



const MoveOutDetailsContainer = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  
  div:nth-child(1) {
    flex: 0 1 49%;
    min-width: 298px;
    margin:0 0 10px 0;
  }

  div:nth-child(2) {
    flex: 0 1 49%;
    min-width: 260px;
    max-width: 404px;
    
    div:nth-child(2) {
      margin-top:0;
      margin-right:5px;
      background:#fff;
    }

  }

`
  ;

const FormGuide = styled.div`
  padding-left: 5px;
  font-weight: 700;
  text-decoration: underline;
  color: #ED1C24;
  padding: 10px 20px 30px;
  `
  ;

const AgencyAddressSelect = styled.div`
  margin-top:10px;
  padding-right: 5px;
  padding-left: 10px;
  `
  ;

const DataEntryPanel = styled.div`
  grid-area: details;
    option {
      border-style: solid;
      border-width: 1px;
      padding: 18px;
      border-radius: 5px;
      position: relative;
      text-align: left;
      cursor: text;
      width: 100%;
    }
`;

const BillingTitle = styled.h3`

@media(max-width:684px){
  margin: 100px 0 0 0;   
}
`
  ;

const emailRegex = /\S+@\S+\.\S+/;
const nameRegex = /^[a-zA-Z'.,()\s-]{5,}$/;
const dateRegex = /([0-3][0-9]|[1-9])\/([1-9]|1[0-2]|0[1-9])\/([12]\d{3}|\d{2})/;

const SingleRecord: React.FC<Props> = ({
  index,
  step,
  item,
  numOfDocs,
  form,
  setForm,
  checkFormValidity
}) => {
  function handleInput(e: any) {
    const value = e.target.value;
    const oldForm = { ...form };
    oldForm[e.target.name] = value;
    setForm({ ...oldForm });
  }

  function handleInputDate(e: any) {
    console.log(e);
    const thisCentury = "20" + e[2].toString();
    const moveOut = [...e[0], "/", ...e[1], "/", thisCentury].join("");
    setForm({ ...form, moveOut });
  }

  const [landlordAddresses, setLandlordAddress] = useState<Array<any>>([]);
  const [chosenAddress, setChosenLLAddress] = useState<any>();
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [dateError, setDateError] = useState("");
  const [formValid, setFormValid] = useState<boolean>();

  useEffect(() => {
    if (emailRegex.test(form.llEmail) || form.llEmail === "") {

      setEmailError('')
    }
    else {
      setEmailError('Please enter a valid email address')
    }

    if (nameRegex.test(form.llName) || form.llName === "") {
      setNameError('')
    }
    else {
      setNameError('Please enter the title and full name')
    }

    if (dateRegex.test(form.moveOut) || form.moveOut === "") {
      setDateError('')
    }
    else {
      setDateError('Please enter a valid date')
    }

    if (
      (form.isPropertyVacant === "YES" || form.isPropertyVacant === "NO") &&
      (form.isAgencyAddress === "YES" || form.isAgencyAddress === "NO") &&
      form.correspondance_address_line1 !== "" &&
      form.correspondance_address_postcode !== "" &&
      form.moveOut.length === 10 &&
      form.llEmail.length > 5 &&
      form.llName.length >= 5 &&
      dateError === "" &&
      nameError === "" &&
      emailError === ""

    ) {
      checkFormValidity(true)
    } else {
      checkFormValidity(false)
    }

  }, [
    form.llEmail,
    form.moveOut,
    form.llName,
    form.moveOut,
    form.isPropertyVacant,
    form.isAgencyAddress,
    form.correspondance_address_postcode,
    form.correspondance_address_line1,
    dateError,
    nameError,
    emailError
  ])

  function chooseAddress(e: any): void {
    landlordAddresses.filter((chosenAddress: any) => {
      if (chosenAddress.id === e.target.value) {
        setChosenLLAddress(chosenAddress)
        const correspondance_address_udprn = chosenAddress.id;
        setForm({
          ...form, correspondance_address_udprn,
          correspondance_address_line1: chosenAddress.line1,
          correspondance_address_line2: chosenAddress.line2,
          correspondance_address_post_town: chosenAddress.city,
          correspondance_address_postcode: chosenAddress.postcode,
          correspondance_address_county: chosenAddress.county,
        })
        return "";
      }
    })
  }

  async function getLandlordAddress(postcode: string) {
    const res = await fetch(
      `${process.env.REACT_APP_LOCATION_API}?postcode=${postcode}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_LOCATION_API_KEY}`,
        },
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setLandlordAddress(data);
    }
  }

  function formatAddr(addr: any): string {
    return `${addr.line1}, ${addr.line2 ? addr.line2 + ", " : ""} ${addr.postcode
      }`
  }

  useEffect(() => {
    getLandlordAddress(form.correspondance_address_postcode);
  }, [form.correspondance_address_postcode]);




  useEffect(() => {
    const merged = {};
    Object.assign(merged, form, item);
    setForm(merged);
  }, [item, index]);

  if (step !== index) {
    return null;
  }

  if (!form) return <div>Loading...</div>;

  return (
    <RecordComponent>
      <Counter><h5>{index + 1} of {numOfDocs}</h5></Counter>
      <PanelContainer>
        <PreviewPanel>
          <h2 id="preview-header">{form.address_name}{form.address_name && ', '}{form.address_line_1}, {form.address_postcode}</h2>
          <div id="preview-content">
            <div>
              <h4>Void Owner</h4>
              <Label>Name</Label>
              <span>{form.llName || form.llName}</span>
              <Label>Email</Label>
              <span>{form.llEmail || form.llEmail}</span>
            </div>
            <div>
              <h4>Move Out Details</h4>
              <Label>Move Out Date</Label>
              <span>{form.moveOut || ""}</span>
              <Label>Property Still Vacant</Label>
              <span>{form.isPropertyVacant || form.isPropertyVacant}</span>
            </div>
          </div>
        </PreviewPanel>
        <DataEntryPanel>
          <h3>Void Owner</h3>
          <TextInput onChange={(e: any) => handleInput(e)}
            label="Full name"
            value={form.llName}
            name="llName"
          ></TextInput>
          {<FormGuide>{nameError}</FormGuide>}

          <TextInput onChange={(e: any) => handleInput(e)}
            label="Email"
            value={form.llEmail}
            name="llEmail"
          ></TextInput>
          {<FormGuide>{emailError}</FormGuide>}
          <Spacer>
            <h3>Move Out Details</h3>
            <MoveOutDetailsContainer>
              <div>
                <Label>Is the property currently vacant?</Label>
                <Select defaultValue={'default'} name="isPropertyVacant" onChange={(e) => {
                  handleInput(e);
                }}
                >
                  <option value="default" disabled selected>
                    Please pick an option
                  </option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Select>
              </div>
              <div>
                <Label>Move out date</Label>
                <SteppedInput onChange={handleInputDate}
                  label="Move Out Date (DD/MM/YY)" segmentLength={2}
                  divider={"/"} numOfSegments={3}
                >
                </SteppedInput>
                {<FormGuide>{dateError}</FormGuide>}
              </div>
            </MoveOutDetailsContainer>
          </Spacer>
          <Spacer>
            <BillingTitle>Where should the final bills be sent to?</BillingTitle>
            <AddressContainer>
              <Spacer>
                <Label>Search by postcode</Label>
                <TextInput onChange={(e: any) => handleInput(e)}
                  label="Postcode"
                  value={form.correspondance_address_postcode}
                  name="correspondance_address_postcode"
                ></TextInput>
                <LabelContainer>
                  <label htmlFor="address">
                    <Select defaultValue="default" name="address" id="address" onChange={(e) => { chooseAddress(e) }}>
                      <option value={"default"} disabled>Select your property...</option>
                      {
                        landlordAddresses.map((addressItem: any, index: number) => {
                          return <option value={addressItem.id} key={index}> {formatAddr(addressItem)}</option>
                        })
                      }
                    </Select>
                  </label>
                </LabelContainer>
                <Spacer>
                  <TextInput onChange={(e: any) => handleInput(e)}
                    label="Address Line 1"
                    value={chosenAddress?.line1 || form.correspondance_address_line1}
                    name="correspondance_address_line1"
                  ></TextInput>
                  <TextInput onChange={(e: any) => handleInput(e)}
                    label="Address Line 2"
                    value={chosenAddress?.line2 || form.correspondance_address_line2}
                    name="correspondance_address_line2"
                  ></TextInput>
                  <TextInput onChange={(e: any) => handleInput(e)}
                    label="City"
                    value={chosenAddress?.post_town || form.correspondance_address_post_town}
                    name="correspondance_address_post_town"
                  ></TextInput>
                  <TextInput onChange={(e: any) => handleInput(e)}
                    label="County"
                    value={chosenAddress?.county || form.correspondance_address_county}
                    name="correspondance_address_county"
                  ></TextInput>
                </Spacer>
              </Spacer>
              <Spacer>
                <Label>Is this your agency address?</Label>
                <AgencyAddressSelect>
                  <Select defaultValue={'default'} name="isAgencyAddress" onChange={(e) => {
                    handleInput(e);
                  }}
                  >
                    <option value="default" selected>
                      Please pick an option
                  </option>
                    <option value="YES">Yes</option>
                    <option value="NO">No</option>
                  </Select>
                </AgencyAddressSelect>
              </Spacer>
            </AddressContainer>
          </Spacer>
        </DataEntryPanel>
      </PanelContainer>
    </RecordComponent>
  );
};

export default SingleRecord;