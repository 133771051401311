

  import React, { useState, useEffect, createRef } from "react";
  import styled from "styled-components";
  interface Props {
    label: string;
    onChange: Function;
    segmentLength: number;
    numOfSegments: number;
    divider?: string;
    onFormattedChange?: Function;
  }
  const TextInputContainer = styled.div<{ focused: boolean }>`
    border-style: solid;
    border-color: rgba(188, 190, 192, 0.5);
    border-width: 1px;
    padding: 18px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    text-align: left;
    cursor: text;
    border-color: ${(props) => (props.focused ? "#34bebd" : "")};
    height:59px;
  `;
  const TextInputElement = styled.input`
    outline: none;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
  `;
  const TextInputLabel = styled.span<{ focused: boolean }>`
    position: absolute;
    color: ${(props) => (props.focused ? "#34bebd" : "#000")};
    transform: ${(props) =>
      props.focused ? "translateY(-29px) scale(0.71)" : ""};
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    cursor: text;
    white-space: nowrap;
    text-align: right;
    font-size: 14px;
    background: ${(props) => (props.focused ? "white" : "transparent")};
    padding: 2px 8px;
  `;
  const InputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  `;
  const Divider = styled.span<{ focused: boolean }>`
    font-weight: bold;
    text-align: center;
    display: ${(props) => (props.focused ? "block" : "none")};
  `;
  const SteppedInput: React.FC<Props> = ({
    label,
    onChange,
    onFormattedChange,
    segmentLength,
    numOfSegments,
    divider = "-",
  }) => {
    const [focused, setFocused] = useState(false);
    const [elementRefs, setElementRefs] = useState<any>([]);
    const [values, setValues] = useState<string[]>([]);
    function handleBlur(index: number) {
      if (elementRefs[index].current.value.trim() === "") {
        setFocused(false);
      } else {
        setFocused(true);
      }
    }
    function handleFocus(index: number) {
      elementRefs[index].current.focus();
      setFocused(true);
    }
    function isBackSpace(e: any, index: number) {
      if (e.key === "Backspace") {
        handleChange(e, index);
      }
    }
    function handlePaste(e: any) {
      const clipboard = e.clipboardData.getData("Text");
      let chunks: string[] = [];
      for (
        var i = 0, charsLength = clipboard.length;
        i < charsLength;
        i += segmentLength
      ) {
        chunks.push(clipboard.substring(i, i + segmentLength));
      }
      chunks = chunks.splice(0, numOfSegments);
      const newArr = values.map((item: string, index: number) => {
        if (!chunks[index]) {
          return "";
        }
        return chunks[index];
      });
      setValues(newArr);
      onChange(newArr);
    }
    function handleChange(e: any, index: number) {
      const { value } = e.target;
      const currVals = [...values];
      currVals[index] = value;
      // focus next input if current is full
      if (value.length === segmentLength && index !== elementRefs.length - 1) {
        elementRefs[index + 1].current.focus();
      }
      // handle backspace if current is empty
      if (
        value.length === 0 &&
        currVals[index].length === 0 &&
        index !== 0 &&
        e.key === "Backspace"
      ) {
        elementRefs[index - 1].current.focus();
      }
      setValues(currVals);
      onChange(currVals);
      if (onFormattedChange) {
        onFormattedChange(currVals.join(divider));
      }
    }
    // initialise
    useEffect(() => {
      setElementRefs((elRefs: any) =>
        Array(numOfSegments)
          .fill(Array(numOfSegments))
          .map((_, i) => elRefs[i] || createRef())
      );
      const scaffoldArr = Array.from({ length: numOfSegments }, (v, i) => "");
      setValues(scaffoldArr);
    }, []);
    return (
      <div>
      <TextInputContainer
        focused={focused}
        onFocus={(e: any) => {
          e.stopPropagation();
        }}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        onBlur={() => handleBlur(0)}
      >
        <TextInputLabel
          focused={focused}
          id="text-input"
          onFocus={() => handleFocus(0)}
          onClick={() => handleFocus(0)}
          onBlur={() => handleBlur(0)}
        >
          {label}
        </TextInputLabel>
        <InputContainer>
          {elementRefs.map((seg: any, index: number) => (
            <>
              <TextInputElement
                type="text"
                name={`element-${index}`}
                value={values[index]}
                ref={elementRefs[index]}
                onClick={() => handleFocus(index)}
                onBlur={() => handleBlur(index)}
                onPaste={(e) => handlePaste(e)}
                onChange={(e) => handleChange(e, index)}
                onFocus={() => handleFocus(index)}
                tabIndex={index}
                maxLength={segmentLength}
                onKeyDown={(e) => isBackSpace(e, index)}
              />
              {index !== elementRefs.length - 1 && <Divider focused={focused}>{divider}</Divider>}
            </>
          ))}
        </InputContainer>
      </TextInputContainer>
      </div>
    );
  };
  export default SteppedInput;